.nav-tab__list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  background-color: #efec5c;
}

.nav-tab__list.dark-theme {
  background-color: #021135;
}

.nav-tab__item {
  font-family: "Georgia", serif;
  font-size: 13px;
  font-weight: 600;
}

.nav-tab__link {
  text-decoration: none;
  background-color: #7ec58600; 
  border: none;
  cursor: default;
  text-transform: uppercase;
  padding: 0;
  /* color: #efec5c; */
  /* text-shadow: 
    0.4px 0.4px 0 black, 
    -0.4px 0.4px 0 black, 
    0.4px -0.4px 0 black, 
    -0.4px -0.4px 0 black; */
}

.nav-tab__link.dark-theme {
  background-color: #021135;
  color: #c6c8c7;
  
}

.nav-tab__link:hover {
  background-color: #7ec5869e;
  text-shadow: none;
}

.nav-tab__link.dark-theme:hover {
  color: #ffffff;
}

@media screen and (max-width: 810px) {
  .nav-tab__list {
    gap: 10px;
  }
  .nav-tab__item {
    font-size: 11px;
    font-weight: 600;
  }
}

@media screen and (max-width: 610px) {
  .nav-tab__list {
    flex-direction: column;
    justify-content: normal;
    padding: 20px 0;
  }
}
