.cookie-consent {
  max-width: 90%;
  margin: 0 auto;
  position: fixed;
  z-index: 1000;
  background-color: #7ec586;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.cookie-consent__container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.cookie-consent__block {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.cookie-consent__text {
  color: #ffffff;
  text-align: center;
  font-family: "Georgia", serif;
  font-size: 15px;
  line-height: 1.65;
}

.cookie-consent__button-agree {
  width: 90%;
  padding: 20px 10px;
  border: #7ec586;
}

.cookie-consent__button {
  width: 90%;
  padding: 5px;
  flex: 1;
  margin: 0 15px;
  text-align: center;
}

.cookie-consent.dark-theme {
  background-color: #021135;
}
