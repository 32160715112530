.scientific-center {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.scientific-center.dark-theme {
  background-color: #280089;
}

.scientific-center__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 94px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scientific-center__title {
  margin: 0;
  padding: 10px 0;
  font-size: 22px;
  font-family: "Georgia", serif;
  line-height: 1.35;
  font-weight: 500;
  text-align: center;
}

.scientific-center__title.dark-theme {
  color: #d7d7d7;
}

.scientific-center__subtitle {
  margin: 0;
  padding: 10px 0;
  font-size: 18px;
  font-family: "Georgia", serif;
  line-height: 1.35;
  font-weight: 600;
  text-align: center;
}

.scientific-center__subtitle.dark-theme {
  color: #d7d7d7;
}

.scientific-center__block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scientific-center__text {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.35;
  font-weight: 300;
  color: #203b3b;
  margin: 10px auto;
}

.scientific-center__text.dark-theme {
  color: #ebecf0;
}

.scientific-center__btn {
  width: 40%;
  margin: 25px auto;
  color: rgb(34, 4, 2);
  text-transform: uppercase;
  border-radius: 3px;
  padding: 8px;
  display: inline-block;
  border-color: #424f54;
  font-family: "Georgia", serif;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  z-index: 2;
  background-color: #efec5c;
  box-shadow: 0 0 15px #ebe6e6;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 610px) {
  .scientific-center__wrapper {
    padding: 186px 0 80px;
  }
  .scientific-center__title {
    font-size: 20px;
  }
}
