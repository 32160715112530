.my-education {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0eae8;
}

.my-education.dark-theme {
  background-color: #021c5a;
}

.my-education__container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}

.my-education__img {
  width: 460px;
  height: 560px;
  border-radius: 5px;
  object-fit: cover;
  margin-top: 130px;
}

.my-education__img.dark-theme {
  filter: brightness(80%);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
}

.my-education__title {
  color: #203b3b;
  font-family: "Georgia", serif;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.23;
  text-align: center;
  margin: 0 auto;
}

.my-education__title.dark-theme {
  color: #c9cac9;
}

.my-education__line {
  max-width: 50px;
  width: 100%;
  height: 3px;
  background-color: #666;
  margin: 25px auto 40px;
}

ul {
  list-style: circle;
  margin: 0;
}

.my-education__li {
  color: #62bf17;
  font-size: 1em;
}

li.dark-theme {
  color: #c9cac9;
}

.my-education__text {
  font-family: "Georgia", serif;
  font-weight: 400;
  color: #000;
  text-align: left;
  font-size: 20px;
  line-height: 1.55;
  margin: 0;
  padding-left: 5px;
}

.my-education__text.dark-theme {
  color: #c9cac9;
}

ul.dark-theme {
  color: #a3a7a3;
}

@media screen and (max-width: 1170px) {
  .my-education__container {
    max-width: 968px;
    gap: 25px;
  }

  .my-education__img {
    width: 380px;
    height: 450px;
  }

  .my-education__title {
    font-size: 30px;
  }

  .my-education__line {
    margin: 25px auto;
  }
}

@media screen and (max-width: 985px) {
  .my-education__container {
    max-width: 760px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .my-education__img {
    width: 600px;
    height: 850px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 770px) {
  .my-education__container {
    max-width: 590px;
  }

  .my-education__img {
    width: 580px;
    height: 820px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 610px) {
  .my-education__container {
    max-width: 312px;
    padding: 60px 0;
  }

  .my-education__img {
    width: 280px;
    height: 400px;
    margin-top: 25px;
  }

  .my-education__title {
    font-size: 24px;
  }
}
