.no-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  place-items: center;
  width: 100%;
}

.no-access__wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.no-access__title {
  color: #000000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 1.55;
  font-weight: 900;
  margin: 30px 30px 10px;
}

.no-access__title.dark-theme {
  color: #d7d7d7;
}

.no-access__subtitle {
  color: #000000;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.55;
  margin: 0;
}

.no-access__subtitle.dark-theme {
  color: #d7d7d7;
}

.no-access__text {
  width: 90%;
  color: #000000;
  font-size: 18px;
  letter-spacing: 0;
  line-height: normal;
  text-align: left;
  margin: 10px 30px;
}

.no-access__text.dark-theme {
  color: #d7d7d7;
}
.no-access__btn {
  margin: 2px;
  padding: 2px;
  text-align: center;
}

.no-access__button {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  margin: 10px auto 10px;
  padding: 15px 25px;
  font-weight: 600;
  position: relative;
  border: 1px solid #3a3835;
  box-shadow: 0 0 10px #52514e;
}

.no-access__button.dark-theme {
  background-color: #c9cac9;
}
