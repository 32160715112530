.process {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #fcf7f7;
}

.process.dark-theme {
  background-color: #001850;
}

.process__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process__title {
  font-size: 38px;
  font-family: "Georgia", serif;
  font-style: italic;
  color: #203b3b;
  text-align: center;
  padding: 0;
  padding-bottom: 90px;
  margin: 0;
}

.process__title.dark-theme {
  color: #d7d7d7;
}

.process__blocks-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.process__block {
  margin: 0 20px;
}

.process__block-title {
  font-family: "Georgia", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #203b3b;
  margin: 0 20px 0 0;
  padding: 6px 0;
}

.process__block-title.dark-theme {
  color: #d7d7d7;
}

.process__line-stroke {
  background-color: #7ec5869e;
  height: 3px;
  width: 100%;
  margin: 14px 20px 14px 0;
}

.process__text {
  font-family: "OpenSans", serif;
  font-size: 18px;
  line-height: 1.55;
  font-style: normal;
  color: #203b3b;
  font-weight: 100;
  padding: 6px 0;
}

.process__line-stroke.dark-theme {
  background-color: #878787;
}

.process__text.dark-theme {
  color: #d7d7d7;
}

.process__text-title {
  font-family: "OpenSans", serif;
  font-size: 18px;
  line-height: 1.55;
  font-style: normal;
  color: #0d1818;
  font-weight: 500;
  padding: 6px 0;
}
.process__text-title.dark-theme {
  color: #f7f4f4;
}

.process__quote {
  width: 80%;
  margin: 30px 0 0;
}

.process__quote-line {
  width: 100%;
  height: 1px;
  text-align: center;
  margin: 65px 0 30px;
  background-color: #c83649;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.process__quote-line.dark-theme {
  background-color: #7a3c3c;
}

.process__quote-title {
  width: 30%;
  background-color: #c83649;
  font-family: "Georgia", serif;
  font-size: 1em;
  display: inline-block;
  color: #fff;
  font-weight: 900;
  padding: 10px 43px;
  margin-top: -19px;
}

.process__quote-title.dark-theme {
  background-color: #7a3c3c;
  color: #d7d7d7;
}

.process__quote-text {
  font-family: "OpenSans", Arial, sans-serif;
  line-height: 1.35;
  font-size: 36px;
  text-align: center;
  color: #203b3b;
  padding: 40px 0 0;
  margin: 0;
}

.process__quote-text.dark-theme {
  color: #d7d7d7;
}

@media screen and (max-width: 985px) {
  .process__blocks-container {
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .process__title {
    padding-bottom: 65px;
  }

  .process__text {
    font-size: 1em;
  }

  .process__text-title {
    font-size: 1em;
  }

  .process__quote-line {
    margin: 45px 0 30px;
  }
}

@media screen and (max-width: 770px) {
  .process__quote-title {
    width: 35%;
    font-size: 0.75em;
  }

  .process__blocks-container {
    width: 500px;
  }

  .process__title {
    width: 500px;
    padding-bottom: 45px;
    font-size: 30px;
  }

  .process__quote {
    margin: 0;
  }

  .process__quote-text {
    font-size: 26px;
    padding: 20px 0 0;
  }

  .process__line-stroke {
    margin: 10px 20px 0 0;
  }
}

@media screen and (max-width: 520px) {
  .process__wrapper {
    max-width: 320px;
    padding: 40px 0 30px;
  }

  .process__blocks-container {
    width: 310px;
  }

  .process__title {
    width: 320px;
  }

  .process__line-stroke {
    margin: 8px 12px 0 0;
  }

  .process__quote-text {
    font-size: 20px;
    padding: 20px 0 0;
  }

  .process__text {
    padding: 0;
  }
  .process__text-title {
    padding: 0;
  }
}
