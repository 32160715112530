.will-change {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #f0eae8;
}

.will-change.dark-theme {
  background-color: #021c5a;
}

.will-change__wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.will-change__title {
  font-size: 38px;
  font-family: "Georgia", serif;
  font-style: italic;
  color: #203b3b;
  text-align: center;
  padding: 0;
  padding-bottom: 105px;
  margin: 0;
}

.will-change__title.dark-theme {
  color: #c9cac9;
}

.will-change__blocks-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 95px;
}

.will-change__block {
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1fr;
  column-gap: 20px;
}

.will-change__block-title {
  font-size: 1em;
  line-height: 1.35;
  color: #203b3b;
  padding-bottom: 13px;
  margin: 0;
}

.will-change__block-title.dark-theme {
  color: #e8e9e8;
}

.will-change__number {
  font-family: "Georgia", serif;
  font-weight: 600;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #7ec5869e;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  line-height: 60px;
}

.will-change__number.dark-theme {
  color: #e8e9e8;
  background-color: #021135;
}

.will-change__text {
  font-family: "Georgia", serif;
  margin: 0;
  color: #203b3b;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.55;
}

.will-change__text.dark-theme {
  color: #e8e9e8;
}

@media screen and (max-width: 1170px) {
  .will-change__wrapper {
    max-width: 968px;
  }
}

@media screen and (max-width: 985px) {
  .will-change__wrapper {
    max-width: 768px;
  }

  .will-change__blocks-container {
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  .will-change__title {
    width: 600px;
    font-size: 36px;
    padding-bottom: 80px;
  }

  .will-change__block {
    grid-template-columns: 40px 1fr;
  }

  .will-change__block-title {
    padding-bottom: 10px;
  }

  .will-change__number {
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
  }
}

@media screen and (max-width: 770px) {
  .will-change__wrapper {
    max-width: 500px;
  }

  .will-change__title {
    width: 500px;
    padding-bottom: 50px;
  }

  .will-change__blocks-container {
    width: 500px;
    row-gap: 30px;
  }
}

@media screen and (max-width: 520px) {
  .will-change__wrapper {
    max-width: 320px;
  }

  .will-change__title {
    width: 320px;
  }

  .will-change__blocks-container {
    width: 310px;
    row-gap: 25px;
  }

  .will-change__block-title {
    font-size: 14px;
  }

  .will-change__block {
    grid-template-columns: 30px 1fr;
  }

  .will-change__number {
    width: 30px;
    height: 30px;
    font-size: 1em;
    line-height: 30px;
  }
}
