.dropdown {
  position: absolute;
  background-color: rgb(237, 241, 231);
  border: 1px solid #7ec5869e;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  cursor: none;
}

.dropbtn {
  font-family: "Georgia", serif;
  background-color: rgb(237, 241, 231);
  color: #11201e;
  border: none;
  padding: 5px 10px;
  font-size: 0.9375em;
  font-weight: 700;
  cursor: pointer;
}

@media screen and (max-width: 610px) {
  .dropdown {
    padding: 0;
    align-items: center;
  }
}
