.telegram {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #fcf7f7;
}

.telegram.dark-theme {
  background-color: #001850;
}

.telegram__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 54px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.telegram__title {
  max-width: 90%;
  margin: 0 auto;
  padding: 0;
  font-size: 22px;
  font-family: "Georgia", serif;
  line-height: 1.55;
  font-weight: 700;
  color: #203b3b;
  text-align: center;
}

.telegram__title.dark-theme {
  color: #d7d7d7;
}

.telegram__blocks {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 30px auto;
  gap: 20px;
}

.telegram__block {
  display: flex;
  flex-direction: column;
}

.telegram__line {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px;
}

.telegram__block-img {
  width: 65px;
  height: 65px;
}

.telegram__block-text {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300;
  color: #203b3b;
}

.telegram__block-text.dark-theme {
  color: #ebecf0;
}

.telegram__photo {
  width: 60%;
  height: auto;
  /* border-radius: 5px; */
  /* box-shadow: 0 0 25px rgb(104 99 99); */
}

.telegram__photo.dark-theme {
  filter: brightness(80%);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 985px) {
  .telegram__line {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .telegram__block-img {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 770px) {
  .telegram__block-text {
    font-size: 1em;
    line-height: 1.45;
  }

  .telegram__blocks {
    width: 70%;
  }
}

@media screen and (max-width: 610px) {
  .telegram__title {
    font-size: 22px;
  }
}
