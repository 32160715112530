.app {
  font-style: normal;
  font-weight: 400;
  box-sizing: border-box;
  font-size: 16px;
  padding: 0;
  margin: auto;
  color: #000;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 320px;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app.dark-theme {
  background-color: #021135;
}

.hover:hover {
  opacity: 0.7;
  cursor: pointer;
}

a {
  transition: 0.3s ease;
}

/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 800; 
} */
