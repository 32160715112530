.header {
  width: 100%;
  min-height: 74px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #7ec5869e;
  position: fixed;
  z-index: 500;
  display: flex;
  align-items: center;
  background-color: rgba(126, 197, 134, 0.5); /* Полупрозрачный фон */
  backdrop-filter: blur(5px);
}

.header__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 0;
  display: grid;
  grid-template-columns: 40px auto auto;
  align-items: center;
  gap: 25px;
}

.header.dark-theme {
  background-color: #021135;
}

.header__logo img {
  width: 50px;
  height: 50px;
}

.header__block {
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 18px;
}

.header__theme img {
  width: 45px;
  height: 45px;
}

.header__btn {
  color: rgb(34, 4, 2);
  text-transform: uppercase;
  border-radius: 3px;
  padding: 8px;
  display: inline-block;
  border-color: #424f54;
  font-family: "Georgia", serif;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  z-index: 2;
  background-color: #efec5c;
  box-shadow: 0 0 10px #ebe6e6;
  overflow: hidden;
  position: relative;
  border-width: 1.5px;
}

.header__btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #b19f03;
  z-index: -1;
  transition: height 0.5s ease;
}

.header__btn:hover::before {
  height: 100%;
}

@media screen and (max-width: 810px) {
  .header__btn {
    font-size: 0.7em;
    padding: 5px;
  }
  .header__wrapper {
    gap: 15px;
  }
  .header__block {
    gap: 7px;
  }
  .header__theme img {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 610px) {
  .header__block {
    flex-direction: column;
  }
  .header__wrapper {
    grid-template-columns: 40px 1fr 1fr;
  }
  .header__theme img {
    width: 45px;
    height: 45px;
  }
}
