.privacy-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  place-items: center;
  width: 100%;
}

.privacy-policy__wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.privacy-policy__title {
  color: #000000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 1.55;
  text-align: center;
  font-weight: 900;
  margin: 30px 30px 0;
}

.privacy-policy__title.dark-theme {
  color: #d7d7d7;
}

.privacy-policy__text {
  color: #000000;
  font-size: 18px;
  letter-spacing: 0;
  line-height: normal;
  text-align: left;
  margin: 30px;
}

.privacy-policy__text.dark-theme {
  color: #d7d7d7;
}

.privacy-policy__button {
  color: #ff6838;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  margin: 10px auto 10px;
  padding: 15px 25px;
  font-weight: 600;
  position: relative;
  border: 2px solid #3a3835;
  border-radius: 10px;
  box-shadow: 0 0 10px #52514e;
}

.privacy-policy__button.dark-theme {
  background-color: #c9cac9;
  color: #ed410b;
}
