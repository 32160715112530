.not-found-page {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}

.not-found-page__wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.not-found-page__title {
  color: #000000;
  font-size: 140px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  font-weight: 400;
  padding: 30px 0 5px;
  margin: 0;
}

.not-found-page__title.dark-theme {
  color: #c9cac9;
}

.not-found-page__subtitle {
  color: #000000;
  font-size: 1em;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin: 0;
  margin-bottom: 177px;
}

.not-found-page__subtitle.dark-theme {
  color: #c9cac9;
}

.not-found-page__button {
  color: #ff6838;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  margin: 10px 0 10px;
  padding: 15px 25px;
  font-weight: 600;
  position: relative;
  border: 2px solid #3a3835;
  border-radius: 10px;
  box-shadow: 0 0 10px #52514e;
}

.not-found-page__button.dark-theme {
  background-color: #c9cac9;
  color: #ed410b;
}
