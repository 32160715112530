.quote {
  width: 100%;
  margin: 0 auto;
  padding: 114px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote.dark-theme {
  background-color: #001850;
}

.quote__wrapper {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote__title {
  max-width: 90%;
  margin: 20px auto;
  padding: 0;
  font-size: 22px;
  font-family: "Georgia", serif;
  line-height: 1.25;
  font-weight: 400;
  text-align: center;
  color: #203b3b;
}

.quote__title.dark-theme {
  color: #d7d7d7;
}

.quote__box {
  max-width: 90%;
  margin: 20px auto;
  padding: 20px;
  font-size: 18px;
  font-family: "Georgia", serif;
  line-height: 1.25;
  font-weight: 400;
  text-align: center;
  border: 2px solid #7ec5869e;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #d7d7d7;
}

.quote__box-loading {
  width: 100%;
  background-color: #7ec5869e;
  font-size: 30px;
  font-family: "Georgia", serif;
  line-height: 1.25;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

.quote__box blockquote {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #203b3b;
  font-weight: 600;
}

.quote__box.dark-theme blockquote {
  color: #d7d7d7;
}

.quote__box footer {
  font-family: "Georgia", serif;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #203b3b;
  text-align: right;
  width: 100%;
}

.quote__box.dark-theme footer {
  color: #d7d7d7;
}

.quote__description {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 18px;
  font-family: "Georgia", serif;
  line-height: 1.25;
  font-weight: 500;
  text-align: center;
  color: #203b3b;
}

.quote__description.dark-theme {
  color: #d7d7d7;
}

.quote__button {
  width: 50%;
  color: #ffffff;
  border-radius: 3px;
  padding: 0 10px;
  display: inline-block;
  font-family: "Georgia", serif;
  height: 60px;
  border: 0 none;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  font-weight: 700;
  cursor: pointer;
  background-color: #7ec586;
}

.quote__button:disabled {
  background-color: #ebd0d0;
  cursor: not-allowed;
  color: #bdbcbc;
  border: none;
}

.quote__button.dark-theme:disabled {
  background-color: #ebd0d0;
  color: #bdbcbc;
  border: none;
}

.quote__button.dark-theme {
  border: 1px solid #0966bd;
  box-shadow: 0 0 10px rgba(165, 159, 214, 0.8);
  background-color: #559f1c;
}

.quote__button:hover.dark-theme {
  background-color: #6d8480;
  color: #01133e;
}

.quote__button:hover {
  background-color: #559f1c;
  color: #000;
}

@media screen and (max-width: 610px) {
  .quote {
    padding: 164px 0 60px;
  }
  .quote__button:disabled {
    background-color: #ebd0d0; 
    cursor: not-allowed;
    color: #bdbcbc;
    border: none;
  }

  .quote__button.dark-theme:disabled {
    background-color: #ebd0d0; 
    color: #bdbcbc;
    border: none;
  }
}
