.price {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  align-items: center;
  background-color: #e4e2e5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price.dark-theme {
  background-color: #021c5a;
}

.price__wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.price__container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("../../images/fonLight.png");
  background-repeat: no-repeat;
  margin: 0 auto;
  background-size: cover;
  filter: blur(2px);
}

.price__container.dark-theme {
  background-image: url("../../images/fonDark.png");
}

.price__container-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  background-color: rgb(126 197 134 / 20%);
  max-height: 90%;
  border-radius: 20px;
  width: 90%;
  margin-top: 50px;
}

.price__title {
  margin: 0;
  padding: 26px 0;
  color: #203b3b;
  font-size: 36px;
  font-family: "Georgia", serif;
  line-height: 1.55;
  font-weight: 400;
}

.price__title.dark-theme {
  color: #c9cac9;
}

.price__text {
  color: #203b3b;
  font-size: 20px;
  font-family: "OpenSans", sans-serif;
  line-height: 1.55;
  font-weight: 400;
  padding: 0 0 30px 30px;
  margin: 0;
}

.price__text.dark-theme {
  color: #c9cac9;
}

.price__wrapper-block {
  position: sticky;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 0 80px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 35px;
  z-index: 2;
}

.price__block {
  border: 1px solid #c83649;
  background-color: #f0eae8;
  border-radius: 3px;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price__block.dark-theme {
  background-color: #021c5a;
  border: 1px solid #7ec5869e;
  filter: brightness(80%);
  box-shadow: 0 0 10px rgba(49, 85, 202, 0.8);
}

.price__block-title {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 22px;
  line-height: 1.35;
  color: rgb(32, 59, 59);
  text-transform: uppercase;
}

.price__block-title.dark-theme {
  color: #f4e1e1;
}

.price__block-duration {
  margin: 0;
  margin-top: 20px;
  padding: 0;
  font-family: "Georgia", serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.55;
  color: rgb(32, 59, 59);
  height: 22px;
}

.price__block-duration.dark-theme {
  color: #f7f2f2;
}

.price__block-cost {
  font-family: "Georgia", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.55;
}

.price__block-cost.dark-theme {
  color: #f4e1e1;
}

.price__block-btn {
  color: #ffffff;
  border-radius: 3px;
  padding: 0 10px;
  display: inline-block;
  font-family: "Georgia", serif;
  height: 60px;
  border: 0 none;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  font-weight: 700;
  cursor: pointer;
}

.price__block-btn.dark-theme {
  border: 1px solid #0966bd;
  box-shadow: 0 0 10px rgba(165, 159, 214, 0.8);
}

.price__block-btngreen {
  background-color: #7ec5869e;
}

.price__block-btngreen.dark-theme {
  background-color: #559f1c;
}

.price__block-btnred {
  background-color: #c83649;
}

.price__block-btnred.dark-theme {
  background-color: #c90b25;
}

.price__block-btngreen:hover {
  background-color: #559f1c;
  color: #000;
}

.price__block-btngreen:hover.dark-theme {
  background-color: #6d8480;
  color: #01133e;
}

.price__block-btnred:hover {
  background-color: #c90b25;
  color: #000;
}

.price__block-btnred:hover.dark-theme {
  color: #01133e;
}

.price__block-btn:hover {
  border-width: 3px;
  border-style: outset;
  border-color: buttonborder;
}

@media screen and (max-width: 1170px) {
  .price__wrapper {
    max-width: 968px;
    gap: 30px;
  }
}

@media screen and (max-width: 985px) {
  .price__wrapper-block {
    max-width: 768px;
  }
}

@media screen and (max-width: 870px) {
  .price__wrapper-block {
    max-width: 590px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 610px) {
  .price__title {
    font-size: 20px;
  }

  .price__text {
    font-size: 16px;
  }

  .price__wrapper-block {
    max-width: 475px;
  }
}

@media screen and (max-width: 485px) {
  .price__wrapper-block {
    max-width: 310px;
  }
}
