.footer {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #7ec5869e;
  display: flex;
  padding: 20px 0 10px;
  z-index: 10;
  margin-top: -1px;
}

.footer.dark-theme {
  background-color: #021135;
}

.footer__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.footer__title {
  font-family: "Georgia", serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 80%;
}

.footer__title.dark-theme {
  color: #c6c8c7;
}
.footer__block {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}

.footer__copyright {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.footer__copyright.dark-theme {
  color: #c6c8c7;
}
.footer__link-telegram {
  width: 24px;
  height: 24px;
}

.footer__block-info {
  display: flex;
  gap: 20px;
}

.footer__btn {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: center;
  box-sizing: content-box;
  margin: 0;
  padding: 0;
  border: 0;
  color: #ffffff;
  text-decoration: none;
  background-color: #7ec58600;
}

.footer__btn.dark-theme {
  background-color: #021135;
  color: #c6c8c7 !important;
}

@media screen and (max-width: 885px) {
  .footer__title {
    font-size: 18px;
  }
}

@media screen and (max-width: 610px) {
  .footer__block-info {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
