.my-diplomas {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fcf7f7;
}

.my-diplomas.dark-theme {
  background-color: #001850;
}

.my-diplomas__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-diplomas__title {
  color: #203b3b;
  font-family: "Georgia", serif;
  font-weight: 400;
  font-size: 36px;
  line-height: 1.55;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.my-diplomas__title.dark-theme {
  color: #d7d7d7;
}

/* // */
.my-diplomas__container {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 250px;
}

.my-diplomas__inner-container {
  display: flex;
  height: 100%;
  animation: scroll 45s linear infinite;
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000px;
  gap: 10px;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-50%));
  }
}

.my-diplomas__slide {
  width: calc(100% / var(--slides-count));
  flex-shrink: 0;
  object-fit: cover;
  cursor: zoom-in;
}

.my-diplomas__arrow-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.my-diplomas__arrow {
  width: 50px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  z-index: 10;
}

.my-diplomas__left-arrow {
  transform: rotate(-180deg);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: #7ec5869e;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
}

.modal-content img {
  display: block;
  max-height: 75vh;
  max-width: 75vw;
  object-fit: cover;
  cursor: zoom-out;
}

.modal-close {
  background-size: 32px;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  position: absolute;
  top: -40px;
  right: -40px;
  background: transparent url(../../images/btnCloseLight.svg) no-repeat center;
}


@media screen and (max-width: 610px) {
  .my-diplomas__container {
    max-width: 310px;
    gap: 30px;
  }

  .my-diplomas__container {
    height: 150px;
  }

  .my-diplomas__title {
    font-size: 24px;
  }
}
