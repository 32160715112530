.contact-form {
  width: 100%;
  margin: 0 auto;
  padding: 114px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fcf7f7;
}

.contact-form.dark-theme {
  background-color: #021c5a;
}

.contact-form__container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 30px;
}

.contact-form__container-title {
  margin: 0;
  padding: 20px 0;
  color: #203b3b;
  font-size: 1.8em;
  font-family: "Georgia", serif;
  line-height: 1.35;
  font-weight: 400;
  text-align: center;
}

.contact-form__container-title.dark-theme {
  color: #c9cac9;
}

.contact-form__container-text {
  color: #203b3b;
  font-size: 18px;
  font-family: "OpenSans", sans-serif;
  line-height: 1.55;
  font-weight: 400;
  padding: 0 0 30px 40px;
  margin: 0;
}

.contact-form__container-text.dark-theme {
  color: #c9cac9;
}

.contact-form__wrapper {
  width: 60%;
  margin: 0 auto;
  padding: 50px 10px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #7ec5869e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form__wrapper.dark-theme {
  background-color: #001850;
}

.contact-form__subtitle {
  width: 90%;
  margin: 0 auto;
  font-family: "Georgia", serif;
  font-weight: 400;
  line-height: 1.3;
  padding: 10px 0 50px;
  font-weight: 600;
  font-size: 1em;
  text-align: center;
  color: #203b3b;
}

.contact-form__subtitle.dark-theme {
  color: #d7d7d7;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input[type="text"],
.contact-form input[type="tel"],
.contact-form input[type="checkbox"] {
  font-family: "Georgia", serif;
  text-transform: uppercase;
  padding: 10px 10px 5px;
  background-color: #fcf7f7;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #7ec586;
  font-size: 0.6em;
  color: #000;
  cursor: text;
  outline: none;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="tel"]:focus,
.contact-form input[type="checkbox"]:focus {
  outline: none;
  box-shadow: none;
}

.contact-form input[type="file"] {
  display: none;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-group label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
  font-size: 1em;
  font-family: "Georgia", serif;
  font-weight: 600;
}

.radio-group.dark-theme label {
  color: #d7d7d7;
}

.radio-group input[type="radio"] {
  margin-right: 0.5em;
  accent-color: #3d7512;
}

.radio-group.dark-theme input[type="radio"] {
  accent-color: #001850;
}

.radio-group input[type="radio"]:checked {
  background-color: #3d7512;
  border-color: #3d7512;
}

.radio-group input[type="radio"]:focus {
  outline: none;
  box-shadow: 0 0 3px #4caf50;
}

.contact-form .file-label {
  font-family: "Georgia", serif;
  font-size: 0.9em;
  font-weight: 400;
  display: inline-block;
  padding: 10px 15px;
  background-color: #7ec586;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.contact-form .file-label.dark-theme {
  background-color: #021135;
}

.contact-form .file-label:hover {
  background-color: #559f1c;
}

.contact-form .file-label.dark-theme:hover {
  background-color: #021c5a;
  border-color: #021135;
}

.contact-form .file-info {
  margin-bottom: 10px;
  font-size: 0.8em;
  color: #000;
}

.contact-form .file-info.dark-theme {
  color: #d7d7d7;
}

.contact-form .checkbox-container {
  width: 90%;
  justify-content: space-around;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-form__checkbox-box {
  max-width: 30%;
  height: 30px;
}

.contact-form__checkbox-text {
  font-family: "Georgia", serif;
  font-size: 0.8em;
}

.contact-form .error {
  color: red;
  font-size: 0.7em;
  margin: 5px auto;
}

.contact-form__checkbox-error {
  width: 30%;
  font-family: "Georgia", serif;
  color: red;
  font-size: 0.7em;
  margin: 5px auto;
}

.contact-form__message textarea {
  font-family: "Georgia", serif;
  padding: 10px 10px 5px;
  background-color: #fcf7f7;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #7ec586;
  font-size: 0.8em;
  font-weight: 600;
  color: #000;
  cursor: text;
  outline: none;
}

.contact-form__block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact-form__block-text {
  font-family: "Georgia", serif;
  font-size: 0.7em;
  text-align: left;
  font-weight: 400;
}

.contact-form__block-text.dark-theme {
  color: #d7d7d7;
}

.contact-form button[type="submit"] {
  font-family: "Georgia", serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  color: rgb(18, 50, 61);
  border-radius: 6px;
  padding: 6px;
  display: inline-block;
  border-color: #7ec586;
  text-align: center;
  z-index: 2;
  background-color: #efec5c;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-form button[type="submit"]::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #b19f03;
  z-index: -1;
  transition: height 0.5s ease;
}

.contact-form button[type="submit"]:hover::before {
  height: 100%;
}

.contact-form button[type="submit"]:hover {
  color: rgb(242, 247, 244);
  background-color: #b19f03;
}

.contact-form ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #202020;
}

.contact-form :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #202020;
  opacity: 1;
}

.contact-form ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #202020;
  opacity: 1;
}

.contact-form :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #202020;
}

.contact-form ::placeholder {
  /* Standard (Chrome, Firefox, Opera, Safari) */
  color: #202020;
}

.submission-status {
  font-family: "Georgia", serif;
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  background-color: #fcf7f7;
  font-size: 1em;
  text-align: center;
}

.submission-status.error {
  background-color: #f2dede;
  color: #a94442;
}

@media screen and (max-width: 610px) {
  .contact-form {
    padding: 220px 0 60px;
  }
  .contact-form__wrapper {
    width: 80%;
  }
  .contact-form__block-text {
    font-size: 0.5em;
  }
}
