.main {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  padding: 0 0 80px 0;
}

.main.dark-theme {
  background-color: #001850;
}

.main__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main__photo {
  width: 480px;
  height: 526px;
  border-radius: 5px;
  box-shadow: -10px -14px 18px rgba(201, 8, 8, 0.1);
}

.main__photo.dark-theme {
  filter: brightness(85%);
  box-shadow: -20px -19px 18px rgba(252, 252, 252, 0.1);
}

.main__blok-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: white;
  width: 425px;
  height: 440px;
  border-radius: 5px;
  border: 1px solid #203b3b;
  margin-top: 160px;
  margin-left: -25px;
  padding: 7px;
}

.main__blok-info.dark-theme {
  background-color: #d4d8d6;
  border: 1px solid black;
  z-index: 2;
}

.main__title {
  margin: 0;
  padding: 0;
  padding-top: 40px;
  color: #203b3b;
  font-size: 42px;
  font-family: "Georgia", serif;
  line-height: 1.55;
  font-weight: 600;
}

.main__subtitle {
  width: 80%;
  margin: 0 auto;
  color: #203b3b;
  font-size: 36px;
  line-height: 1.35;
  font-weight: 400;
  text-align: center;
  padding: 0;
}

.main__text {
  color: #203b3b;
  font-size: 22px;
  line-height: 1.35;
  font-weight: 300;
  padding: 0 7px;
  margin: 0;
  text-align: center;
}

.main__link {
  color: #ffffff;
  font-size: 14px;
  font-family: "Open Sans", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 600;
  border-color: transparent;
  border-radius: 63px;
  background-color: #c83649;
  width: 50%;
  height: 60px;
  padding: 10px 0;
  box-shadow: 0 0 10px rgba(133, 50, 74, 0.8);
}

.main__link.dark-theme {
  background-color: #021c5a;
  border-color: #203b3b;
  color: #c6c8c7;
  box-shadow: 0 0 5px rgba(212, 65, 65, 0);
}

@media screen and (max-width: 985px) {
  .main__blok-info {
    width: 400px;
    height: 456px;
    margin-left: -25px;
    margin-top: 218px;
  }

  .main__photo {
    width: 430px;
    height: 490px;
  }

  .main__title {
    font-size: 28px;
  }

  .main__subtitle {
    font-size: 24px;
  }

  .main__text {
    font-size: 24px;
  }
}

@media screen and (max-width: 770px) {
  .main {
    padding: 80px 0;
  }

  .main__wrapper {
    padding-bottom: 200px;
  }
  .main__photo {
    width: 350px;
    height: 415px;
  }

  .main__blok-info {
    width: 400px;
    height: 300px;
    margin: 0;
    margin-left: -150px;
    margin-bottom: -550px;
  }

  .main__title {
    padding: 0;
  }
  .main__text {
    font-size: 20px;
  }
}

@media screen and (max-width: 610px) {
  .main {
    padding: 60px 0 80px;
  }
  .main__wrapper {
    flex-direction: column;
    align-items: center;
    gap: 22px;
    padding: 0;
    margin: 0 auto;
  }

  .main__photo {
    padding: 0;
    max-width: 310px;
    height: 340px;
  }

  .main__blok-info {
    width: 95%;
    height: 292px;
    margin: 0 auto;
    gap: 26px;
  }

  .main__title {
    font-size: 26px;
  }

  .main__subtitle {
    font-size: 18px;
  }

  .main__text {
    font-size: 1em;
  }

  .main__link {
    height: 50px;
  }
}
