.contacts {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.contacts.dark-theme {
  background-color: #280089;
}

.contacts__wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.contacts__container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url("../../images/fonLight.png");
  background-repeat: no-repeat;
  margin: 0 auto;
  background-size: cover;
  background-position: top;
}

.contacts__container.dark-theme {
  background-image: url("../../images/fonDark.png");
}

.contacts__block {
  width: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts__photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0;
}

.contacts__photo.dark-theme {
  filter: brightness(80%);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
}

.contacts__title {
  color: #203b3b;
  font-size: 30px;
  line-height: 1.35;
  font-family: "Georgia", serif;
  font-weight: 600;
  text-align: center;
  margin: 30px auto;
}

.contacts__title.dark-theme {
  color: #c9cac9;
}

.contacts__text {
  color: #203b3b;
  font-size: 1em;
  line-height: 26px;
  font-family: "Georgia", serif;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.contacts__text.dark-theme {
  color: #c9cac9;
}

.contacts__btn {
  width: 80%;
  height: 65px;
  margin: 0;
  border: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  border-radius: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts__btn.dark-theme {
  filter: brightness(90%);
  border: 1px solid #0966bd;
}
.contacts__btn-telegram {
  background-color: #0087d0;
  margin: 40px 0;
}

.contacts__btn-whatsapp {
  background-color: #27d061;
}

.contacts__btn:hover {
  color: #082c15;
}

@media screen and (max-width: 610px) {
  .contacts__block {
    max-width: 280px;
  }

  .contacts__photo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 40px 0;
  }

  .contacts__btn {
    height: 45px;
  }

  .contacts__btn-telegram {
    margin: 20px 0;
  }

  .contacts__line {
    margin: 18px auto;
  }
}
