.career-page {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #fcf7f7;
}

.career-page.dark-theme {
  background-color: #001850;
}

.career-page__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 114px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.career-page__title-block {
  max-width: 90%;
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  padding: 0;
  color: #203b3b;
  align-items: flex-start;
  gap: 10px;
}

.career-page__title {
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-family: "Georgia", serif;
  line-height: 1.35;
  font-weight: 400;
  text-align: center;
}

.career-page__title.dark-theme {
  color: #d7d7d7;
}

.career-page__text {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300;
  color: #203b3b;
  margin: 40px auto;
}

.career-page__text.dark-theme {
  color: #ebecf0;
}

.career-page__btn-price {
  width: 40%;
  color: rgb(34, 4, 2);
  text-transform: uppercase;
  border-radius: 3px;
  padding: 8px;
  display: inline-block;
  border-color: #424f54;
  font-family: "Georgia", serif;
  font-weight: 600;
  font-size: 0.9em;
  text-align: center;
  z-index: 2;
  background-color: #efec5c;
  box-shadow: 0 0 15px #ebe6e6;
  overflow: hidden;
  position: relative;
}

.career-page__img-small {
  width: 80px;
  height: 80px;
}

.career-page__btn-price::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #b19f03;
  z-index: -1;
  transition: height 0.5s ease;
}

.career-page__btn-price:hover::before {
  height: 100%;
}

@media screen and (max-width: 610px) {
  .career-page__wrapper {
    padding: 206px 0 80px;
  }
  .career-page__title {
    font-size: 20px;
  }
}
